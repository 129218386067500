import React from "react";
import axios from "axios";
import {useHistory} from "react-router-dom";
import './style.scss';
import { connect } from "react-redux";

const Container = (props) => {
  const history = useHistory();
  return <SaveTags
    {...props}
    history={history}/>
}

class SaveTags extends React.Component {
  state = {
    tag: "",
    description: "",
    error: '',
  }
  handleClick = () => {
    const payload = {
      tag: this.state.tag,
      description: this.state.description,
    };
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/tags`, payload)
      .then(() => {
        this.props.history.push("/tags")
      })
      .catch(() => {
        this.setState({error: 'Tag already exists'})
      })
  };

  render() {
    return (
      <div className="save-tags">
        <div className="form-group">
          <label>Tag</label>
          <input
            onChange={e => this.setState({tag: e.target.value})}
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <input
            onChange={e => this.setState({description: e.target.value})}
          />
        </div>
        <div className="form-group">
          <button className="btn" onClick={this.handleClick}>
            Enter
          </button>
        </div>
        <div>{this.state.error}</div>
      </div>
    )
  }
}

const mapStatetoProps = state => {
  return {
    login: state.login
  }}

export default connect(mapStatetoProps)(Container)