import React from 'react';
import axios from 'axios';
import { connect } from "react-redux";

import FormulaCard from '../../App/FormulaCard';

import './style.scss';

class View extends React.Component {
  state = {
    entries: [],
    searchInput: "",
  };

  loadData = () => {
    axios
      .get(this.props.loadEndpoint)
      .then(response => {
        this.setState({
          entries: response.data,
        });
      });
  };

  updateFavorites = (entry) => {
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/formulas/favorites`, {
        formula: entry,
        userId: this.props.login.userId
      })
      .then(this.loadData)
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div className="search-screen">
        <div className="formulas-container">
          {
            this.state.entries.map(entry => (
              <FormulaCard
                key={entry.id}
                {...entry}
                userIsLoggedIn={this.props.login.login}
                isFavorite={entry.isFavorite}
                updateFavorites={this.updateFavorites}
              />
            ))
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
});

export default connect(
  mapStateToProps
)(View)
