import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import './style.scss';

export default class ListTags extends React.Component {
  state = {
    entries: []
  }

  loadTags = () => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/tags`)
      .then(response => {
        this.setState({
          entries: response.data,
        });
      });
  }

  componentDidMount() {
    this.loadTags();
  }

  render() {
    return (
      <div className="list-tags">
        <div className='top-navigation-bar'>
          <Link className="btn" to='/tags/new'>Create New Tags</Link>
          <Link className='btn' to='/'>Back to Home</Link>
        </div>
        <div className="tag-panel">
          {
            this.state.entries.map((entry, index) => (
              <div key={index} className="tag-card">
                <Link to={`/tags/${entry.id}`}>
                  <div className="label-group">
                    <label>Tag</label>
                    <div>{entry.tag}</div>
                  </div>
                  <div className="label-group">
                    <label>Description</label>
                    <div>{entry.description}</div>
                  </div>
                </Link>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}
