import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './style.scss';

export default props => (
  <div className="formula-card">
    <div className="top-row">
      <Link className="formula-name" to={`/formula/${props.id}`}>
        {props.name}
      </Link>
      {
        props.userIsLoggedIn &&
        <button
          className={`favorite-button ${props.isFavorite ? 'selected' : 'unselected'}`}
          onClick={() => props.updateFavorites(props)}
        >
          <FontAwesomeIcon
            icon='star'
          />
        </button>
      }
    </div>
    <Link className="formula-name" to={`/formula/${props.id}`}>
      <div className="formula">
        {props.formula}
      </div>
    </Link>
    <div className="tags">
      {
        props.tags?.map((tag, index) => (
          <div key={index} className="tag">
            {tag.label}
          </div>
        ))
      }
    </div>
  </div>
);
