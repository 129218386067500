import React from "react";
import axios from 'axios';
import { Link, useHistory } from "react-router-dom";
import Autosuggest from 'react-autosuggest';
import queryString from 'query-string';

import './style.scss';

const theme = {
  container: {
    position: 'relative'
  },
  inputFocused: {
    outline: 'none'
  },
  suggestionsContainer: {
    display: 'none'
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 51,
    width: 280,
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    zIndex: 2
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px'
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd'
  }
};

export default () => {
  const history = useHistory();
  return <Search history={history}/>
}

class Search extends React.Component {

  state = {
    suggestions: [],
    q: null,
    hackSomethingSelected: false,
  };

  fetchSuggestions = (value) => {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/search?query=${value}`)
      .then((response) => this.setState({suggestions: response.data}));
  }

  onSuggestionSelected = (event, {suggestion}) => {
    this.setState({hackSomethingSelected: true});

    this.props.history.push({pathname: `/formula/${suggestion.id}`});
  }

  onUserInputChange = newValue => {
    this.setState({
      q: newValue,
      hackSomethingSelected: false,
    });
  }

  handleSearchBarSubmit = e => {
    e.preventDefault();

    if (this.state.hackSomethingSelected) {
      return;
    }

    const oldParams = this.getQueryParameters();

    const removeKey = (k = "", { [k]:_, ...o } = {}) => o;

    const mergedQueryParams = this.state.q
      ? {
        ...oldParams,
        q: this.state.q,
      }
      : removeKey("q", oldParams);

    this.props.history.replace({
      pathname: '/search',
      search: '?' + queryString.stringify(mergedQueryParams),
    });
  }

  getQueryParameters = () => queryString.parse(this.props.history.location.search);

  render() {
    return (
      <form className="search-bar" onSubmit={this.handleSearchBarSubmit}>
        <Autosuggest
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={({value}) => this.fetchSuggestions(value)}
          onSuggestionsClearRequested={() => this.setState({suggestions: []})}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={suggestion => suggestion.name}
          renderSuggestion={SuggestionRow}
          inputProps={{
            placeholder: 'Search...',
            value: this.state.q ?? this.getQueryParameters().q ?? "",
            onChange: (event, _) => this.onUserInputChange(_.newValue),
          }}
          theme={theme}
        />
      </form>
    );
  }
}

const SuggestionRow = props => (
  <Link to={`/formula/${props.id}`} key={props.id} style={{textDecoration: "none"}}>
    {props.name}
  </Link>
);
