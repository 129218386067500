import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Home from "./Home";
import CreateFormula from "./CreateFormula";
import LoginScreen from "./Login"
import ListTags from "./ListTags";
import SaveTags from "./SaveTags";
import FormulaPage from "./FormulaPage";
import CreateUserScreen from "./CreateUser";
import NavBar from "./NavBar";
import TagFormulas from "./TagFormulas";

const View = props => (
  <BrowserRouter>
    <NavBar/>
    <div className="App">
      <Switch>
        <Route exact path="/new" component={CreateFormula}/>
        <Route exact path="/login/new" component={CreateUserScreen}/>
        <Route exact path="/login" component={LoginScreen}/>
        <Route exact path="/tags/new" component={SaveTags}/>
        <Route exact path="/tags" component={ListTags}/>
        <Route exact path="/tags/:tagId" component={TagFormulas}/>
        <Route exact path="/formula/:formulaId" component={FormulaPage}/>
        <Route exact path="/search" component={Home}/>
        <Route exact path="/" component={Home}/>
        <Redirect to='/'/>
      </Switch>
    </div>
  </BrowserRouter>
);

const mapStateToProps = (state) => ({
  login: state.login,
});

export default connect(
  mapStateToProps
)(View)
