import React from "react";

import './style.scss';

export default () => (
  <svg
    className="app-icon"
    width="100%" height="100%" viewBox="0 0 1000 1000" version="1.1" xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="line-1"
      d="M900,752.362C900,697.171 855.192,652.362 800,652.362L200,652.362C144.808,652.362 100,697.171 100,752.362C100,807.554 144.808,852.362 200,852.362L800,852.362C855.192,852.362 900,807.554 900,752.362Z"
    />
    <g transform="matrix(0,1,-1,0,0,0)">
      <path
        className="line-2"
        d="M952.362,-300C952.362,-355.192 907.554,-400 852.362,-400L252.362,-400C197.171,-400 152.362,-355.192 152.362,-300C152.362,-244.808 197.171,-200 252.362,-200L852.362,-200C907.554,-200 952.362,-244.808 952.362,-300Z"
      />
    </g>
  </svg>
);
