import React from "react";

import "./style.scss";

export default props => (
  <div className="formula-input-list">
    {
      props.variables.map(variableName => (
        <div className="input-list-row">
          <div>{variableName}</div>
          <input
            type='text'
            key={variableName}
            placeholder={variableName}
            value={props.variableValues[variableName] || ''}
            onChange={e => props.onChange(variableName, e.target.value)}
          />
        </div>
      ))
    }
  </div>
);
