import React from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import './style.scss';

import { logInOut } from "../../actions/login";

class LoginScreen extends React.Component {
  state = {
    user_email: "",
    password: "",
    errorMessage: "",
  };

  handleSubmit = e => {
    // Prevent the form from reloading the page.
    e.preventDefault();

    const payload = {
      user_email: this.state.user_email,
      password: this.state.password,
    };
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/login`, payload)
      .then((response) => {
          this.props.storeLoginState({
            sessionId: response.data.sessionId,
            login: true,
            userId: response.data.userId,
            user_email: payload.user_email
          });
          // TODO: Parse destination from query params.
          this.props.history.push("/");
        }
      )
      .catch((error) => {
        this.setState({errorMessage: error.message})
      })
  };

  render() {
    return (
      <div className="login-screen">
        <form className="login-form" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input
              onChange={e => this.setState({user_email: e.target.value})}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              onChange={e => this.setState({password: e.target.value})}
            />
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn"
            >
              Login
            </button>
            {
              !this.state.login && (
                <div className="output">
                  <div>{this.state.errorMessage}</div>
                </div>
              )
            }
          </div>
          <div className="new-account-row">
            <Link to="/login/new">Create New Account</Link>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  storeLoginState: loginState => dispatch(logInOut(loginState)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginScreen)
