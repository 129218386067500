import React from "react";

import FormulaList from "../../components/FormulaList";
import { connect } from "react-redux";

export const View = props => (
  <FormulaList
    loadEndpoint={`${process.env.REACT_APP_API_ENDPOINT}/tags/${props.match.params.tagId}/formulas`}
  />
);

const mapStateToProps = (state) => ({
  login: state.login,
});

export default connect(
  mapStateToProps
)(View)

