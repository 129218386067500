import React from "react";

import './style.scss';

export const specialKey = "var";

export const specialNum = "num";

// TODO: Centralize
const termNameToSymbol = {
  'mul': '*',
  'add': '+',
  'exp': '^',
  'eq': '=',
  'neg': '-'
}

const TermSelector = props => {
  const type = props.term.type
  switch (type) {
    case specialNum:
      return <>{props.term.value}</>;
    case specialKey:
      return <>{props.term.variable}</>;
    default:
      return props.term.terms.map((childTerm, index) => (
        <React.Fragment key={index}>
          {(index > 0 || type === 'neg') && <div className="symbol">{termNameToSymbol[type]}</div>}
          <StructuredFormula term={childTerm}/>
        </React.Fragment>
      ));
  }
}

const StructuredFormula = props =>
  <div key={props.key} className={`structured-term ${props.term.type}`}>
    <TermSelector {...props}/>
  </div>

export default StructuredFormula;
