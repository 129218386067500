import React from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable';

import axios from "axios";

export default class SelectTags extends React.Component {

  loadTags = (searchInput) => {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/tags/search`, searchInput)
      .then(response => {
        return response.data.map(_ => ({
          value: _.id,
          label: _.label,
        }));
      });
  };

  render() {
    return (
      <AsyncCreatableSelect
        className={this.props.className}
        placeholder="Tags"
        loadOptions={this.loadTags}
        defaultOptions={{}}
        onChange={selection => this.props.onTagChange(selection.map(_ => ({
          id: _.value,
          label: _.label,
        })))}
        getNewOptionData={(inputValue, optionLabel) => ({
          value: `_${inputValue}`,
          label: optionLabel,
          __isNew__: true
        })}
        isMulti
      />
    )
  }
}
