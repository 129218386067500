export default (state = {}, action) => {
  switch (action.type) {
    case 'USER_LOG_IN_OUT': {
      return action.loginState;
    }

    default:
      return state;
  }
};
