import React from "react";

import FormulaList from "../../components/FormulaList";
import { connect } from "react-redux";

export const View = props => (
  <FormulaList
    loadEndpoint={props.login.userId
      ? `${process.env.REACT_APP_API_ENDPOINT}/formulas`
      : `${process.env.REACT_APP_API_ENDPOINT}/user/${props.login.userId}/formulas`}
  />
);

const mapStateToProps = (state) => ({
  login: state.login,
});

export default connect(
  mapStateToProps
)(View)

