import React from "react";

import './style.scss';
import { specialKey, specialNum } from "../StructuredFormula";

const termNameToSymbol = {
  'mul': '*',
  'add': '+',
  'exp': '^',
  'eq': '=',
  'neg': '-',
}

const TermSelector = props => {
  const type = props.term.type
  switch (type) {
    case specialKey:
      return (
        <input
          type='text'
          key={props.term.variable}
          size='4'
          value={props.variableValues?.[props.term.variable] ?? ''}
          placeholder={props.term.variable}
          onChange={e => props.handleVariableValueChange(props.term.variable, e.target.value)}
        />
      )
    case specialNum:
      return (<div>{props.term.value}</div>)

    default:
      return props.term.terms.map((childTerm, index) => (
        <React.Fragment key={index}>
          {(index > 0 || type === 'neg') && <div className="symbol">{termNameToSymbol[type]}</div>}
          <StructuredInput
            type={childTerm.type}
            term={childTerm}
            handleVariableValueChange={props.handleVariableValueChange}
            variableValues={props.variableValues}
          />
        </React.Fragment>
      ));
  }
}

const StructuredInput = props =>
  <div key={props.key} className={`structured-input structured-term ${props.type}`}>
    <TermSelector {...props}/>
  </div>;

export default StructuredInput;