import React from 'react';
import axios from "axios";

import './style.scss';

export default class CreateUserScreen extends React.Component {
  state = {
    user_email: "",
    password: "",
    errorMessage: ""
  };

  handleCreateUser = () => {
    const payload = {
      user_email: this.state.user_email,
      password: this.state.password,
    };

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/create`, payload)
      .then(() => {
        this.props.history.push("/login")
      })
      .catch(() => {
        this.setState({errorMessage: "The username is already exists"})
      });
  };

  render() {
    return (
      <div className="create-screen">
        <div className="form-group">
          <label>Email</label>
          <input
            onChange={e => this.setState({user_email: e.target.value})}
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input type="password"
            onChange={e => this.setState({password: e.target.value})}
          />
        </div>
        <div className="form-group">
          <button className="btn" onClick={this.handleCreateUser}>
            Create User
          </button>
          {
            this.state.errorMessage && (
              <div className="output">
                <div>{this.state.errorMessage}</div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}