import React from 'react';
import axios from 'axios';
import { connect } from "react-redux";

import CreateFormula from '../FormulaEditor';

class ViewContainer extends React.Component {

  state = {
    formula: null,
  };

  loadFormula = () => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/formulas/${this.props.match.params.formulaId}`)
      .then((response) => {
        this.setState({formula: response.data});
      });
  }

  componentDidMount() {
    this.loadFormula();
  }

  render() {
    return this.state.formula != null &&
      <CreateFormula
        formula={this.state.formula}
      />;
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
});

export default connect(
  mapStateToProps
)(ViewContainer)
