import { library } from '@fortawesome/fontawesome-svg-core'

import {
} from '@fortawesome/free-solid-svg-icons'

import {
  faCaretUp,
  faCaretDown,
  faStar,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faCaretDown,
  faCaretUp,
  faStar
);
