import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Search from "../SearchBar";
import AppIcon from "../../AppIcon";

import { logInOut } from "../../actions/login";

import './style.scss';

const logout = (props) => {
  props.storeLoginState({
    sessionId: null,
    login: false,
    userId: null,
    user_email: 'Guest'
  })
}

const View = props => {
  return (
    <div className='nav-bar'>
      <Link to="/">
        <AppIcon/>
      </Link>
      <Link className="btn" to="/new">Write Equation</Link>
      <Link to='/tags'>Tags</Link>
      {
        props.login.login && <Link to='/favorites'>Favorties</Link>
      }
      <Search/>
      {
        props.login.login
          ? (
            <Link to='/' onClick={() => logout(props)}>
              Logout
            </Link>
          )
          : (
            <Link to='/login'>
              Login
            </Link>
          )
      }
    </div>
  );
}

const mapStatetoProps = state => {
  return {
    login: state.login
  }
}

const mapDispatchtoProps = dispatch => ({
  storeLoginState: loginState => dispatch(logInOut(loginState))
})

export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(View)
