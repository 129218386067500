import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './style.scss';

export default class extends React.Component {

  state = {
    vote: 0,
    totalVotes: null,
  };

  loadFormulaStats = () => {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/formula/${this.props.formula_id}/stats/${this.props.login.userId}`)
      .then((response) => {
        this.setState({
          totalVotes: response.data.totalVotes ? response.data.totalVotes : 0,
          vote: response.data.userVote
        });
      })
  }

  updateVote = newVote => {
    if (this.props.login.login) {
      axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/formula/${this.props.formula_id}/vote`,
        {
          user_id: this.props.login.userId,
          formula_id: this.props.formula_id, vote: newVote
        }
      )
    } else {
      this.props.history.push('/')
    }
  }

  handleUserClick = (clickedButton) => {
    if (this.props.login.login) {
      const newVote = (
        clickedButton === 'up' && this.state.vote !== 1 ? 1
          : clickedButton === 'down' && this.state.vote !== -1 ? -1
          : 0);
      this.setState({vote: newVote})
      this.updateVote(newVote)
    } else {
      this.props.history.push('/login')
    }
  }

  async componentDidMount() {
    await this.loadFormulaStats()
  }

  render() {
    return (
      <div className='vote-buttons'>
        <button
          className={
            `up-button 
              ${this.state.vote === 1 ? 'selected' : 'unselected'}`
          }
          onClick={() => this.handleUserClick('up')}
        >
          <FontAwesomeIcon icon="caret-up"/>
        </button>
        <div className='vote-count'>
          {this.state.totalVotes}
        </div>
        <button
          className={
            `down-button 
              ${this.state.vote === -1 ? 'selected' : 'unselected'}`
          }
          onClick={() => this.handleUserClick('down')}
        >
          <FontAwesomeIcon icon="caret-down"/>
        </button>
      </div>
    );
  }
}
