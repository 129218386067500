import React from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import SelectTags from './SelectTags'
import StructuredInput from "../../components/StructuredInput";
import StructuredFormula from "../../components/StructuredFormula";
import FormulaInputList from "../../components/FormulaInputList";
import VoteButtons from "../../components/VoteButtons";

import './style.scss';

const Container = (props) => {
  const history = useHistory();
  return <View
    {...props}
    history={history}
  />;
}

class View extends React.Component {
  state = {
    formula: {
      // Do not set props. Otherwise getFormula() won't work correctly.
    },
    expressionTree: null,
    variableValues: {},
    resultEvaluate: {},
    dirty: false,
  };

  getFormula = () => ({
    ...this.props.formula,
    ...this.state.formula,
  });

  getExpressionTree = () => this.state.expressionTree ?? this.props.formula?.expressionTree;

  onSave = () => {
    // TODO: Refactor into action
    this.setState({
      saving: true,
    });

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/formulas`, this.getFormula())
      .then(() => this.setState({
        dirty: false,
        saving: false,
        error: null,
      }))
      // TODO: Render error
      .catch(() => this.setState({
        saving: false,
        error: {
          message: 'Error saving formula.'
        },
      }));
  };

  onUpdateExpressionTree = expression =>
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/expression-tree`, {
        expression,
      })
      .then(response => {
        this.setState({
          expressionTree: response.data,
        })
      });

  handleVariableValueChange = (key, value) => {
    const newVariableValues = {
      ...this.state.variableValues,
      [key]: value,
    };
    this.setState({
      variableValues: newVariableValues
    });

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/evaluate/result`, {
        vars: newVariableValues,
        id: this.getFormula().id,
        // TODO: expression not needed if id is set
        expression: this.getFormula().formula,
      })
      .then((response) => this.setState({
        resultEvaluate: response.data,
      }));
  };

  render() {
    const formula = this.getFormula();
    const expressionTree = this.getExpressionTree();

    return (
      <div className="create-formula">
        {
          this.state.error &&
          <section>
            <div className="error-banner">
              {this.state.error.message}
            </div>
          </section>
        }
        <section className="header-section">
          {
            formula.id &&
            <div className="left-column">
              <VoteButtons
                login={this.props.login}
                history={this.props.history}
                formula_id={formula.id}
              />
            </div>
          }
          <div className="fields-column">
            <input
              className="control-row title-input"
              placeholder="Title"
              value={this.getFormula().name}
              onChange={e => this.setState({
                dirty: true,
                formula: {
                  ...this.state.formula,
                  name: e.target.value,
                },
              })}
            />
            <div className="control-row">
              <input
                className="description-input"
                placeholder="Description"
                value={this.getFormula().description}
                onChange={e => this.setState({
                  dirty: true,
                  formula: {
                    ...this.state.formula,
                    description: e.target.value,
                  },
                })}
              />
              <SelectTags
                className="create-tags"
                value={this.getFormula().tags}
                onTagChange={selectedTags => this.setState({
                  dirty: true,
                  formula: {
                    ...this.state.formula,
                    tags: selectedTags,
                  },
                })}
              />
            </div>
            <input
              className="control-row"
              placeholder="Expression (eg, m * x + b)"
              value={this.getFormula().formula}
              onChange={e => {
                const expression = e.target.value;
                this.setState({
                  dirty: true,
                  formula: {
                    ...this.state.formula,
                    formula: expression,
                  },
                });

                this.onUpdateExpressionTree(expression);
              }}
            />
          </div>
        </section>
        {
          this.state.dirty &&
          <section className="control-row">
            <button
              className="save-button btn"
              onClick={this.onSave}
              disabled={this.state.saving}
            >
              {this.props.saveButtonCta}
            </button>
          </section>
        }
        <div className="formula-section">
          <div className="left-column">
            <div className="structured-formula-row">
              {
                expressionTree &&
                <StructuredFormula term={expressionTree}/>
              }
            </div>
            <div className="structured-formula-row">
              {
                expressionTree &&
                <StructuredInput
                  term={expressionTree}
                  handleVariableValueChange={this.handleVariableValueChange}
                  variableValues={this.state.variableValues}
                />
              }
            </div>
            {
              this.state.resultEvaluate?.result && (
                <div className="output-row">
                  <div className="big-equals-sign">=</div>
                  <div className='result'>{this.state.resultEvaluate?.result}</div>
                </div>
              )
            }
          </div>
          <div className="right-column">
            <FormulaInputList
              variables={this.getFormula().variables?.tmp ?? []}
              onChange={this.handleVariableValueChange}
              variableValues={this.state.variableValues}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state, ownProps) => {
  return ({
    login: state.login
  });
}

export default connect(mapStatetoProps)(Container)
